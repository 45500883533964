import React from "react";
import PropTypes from "prop-types";
import { useSiteMetadata } from "../hooks/use-site-metadata"
import Helmet from "react-helmet";
import Logo from "../images/logo.png";


function toIso8601(dateString) {
	// Assuming dateString is in "YYYY-MM-DD" format
	const date = new Date(dateString);
	return date.toISOString();
  }

const SEO = ({ title, description,pathname,questions,children,serviceName,serviceRate,reviews, videoDetails,article,itemList, breadcrumb }) => {
	const { title: defaultTitle, description: defaultDescription, siteUrl,  } = useSiteMetadata()
	
	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		url: `${siteUrl}${pathname || ``}`,
	  };	
	
	const itemListSchema = itemList?{
		"@context": "http://schema.org",
		"@type": "ItemList",
		"itemListElement": itemList.map((item, index) =>(
			{
				"@type": "ListItem",
				"position":index+1,
				"url": item
			}


		)
		
		)

	}: {};

	const breadCrumbSchema = breadcrumb?{
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": breadcrumb.map((item, index) =>(
			{
				"@type": "ListItem",
				"position":index+1,
				"name":item.name,
				"item": item.url
			}


		)
		
		)

	}: {};

	const organizationSchema = {
		"@context": "http://schema.org",
		"@type": "Organization",
		"name": "STAIRAZ",
		"url": siteUrl,
		"logo":Logo,
		"description":"STAIRAZ is a leading company dedicated to boosting your academic and early career achievements. We specialize in offering best career and academic consultancy services, to help you succeed in school and beyond. Our services include a resume builder, insightful job analysis, and a smart essay writer. All our services are backed by professional expertise and cutting-edge AI technology, ensuring you get the best support for your success journey. Simple, effective, and tech-savvy, STAIRAZ is your go-to partner for academic and career excellence!",
		// Add more organization details here
	  };
	  
	  const product = serviceRate?{
		"@context": "http://schema.org",
		"@type": "Product",
		"name": serviceName,
		"description":description,
		"review":reviews,
		"aggregateRating": {
		  "@type": "AggregateRating",
		  "ratingValue": serviceRate.ratingValue,
		  "ratingCount": serviceRate.ratingCount
		}
		// Add more CreativeWorkSeries details here
	  }: {};

	  const creativeWorkSeriesSchema = serviceRate?{
		"@context": "http://schema.org",
		"@type": "CreativeWorkSeries",
		"name": serviceName,
		"aggregateRating": {
		  "@type": "AggregateRating",
		  "ratingValue": serviceRate.ratingValue,
		  "ratingCount": serviceRate.ratingCount,
		}
		// Add more CreativeWorkSeries details here
	  }: {};
	  
	  // Then you can use this schema in a <script
	  

	const faqSchema = questions?{
	"@context": "http://schema.org",
	"@type": "FAQPage",
	"mainEntity": questions.map(q => ({
		"@type": "Question",
		"name": q.question,
		"acceptedAnswer": {
		"@type": "Answer",
		"text": q.answer
		}
	}))
	} : {};

	const videoSchema =videoDetails?{
		"@context": "http://schema.org",
		"@type": "VideoObject",
		"name": videoDetails.title,
		"description": videoDetails.description,
		"thumbnailUrl": videoDetails.thumbnailUrl,
		"uploadDate": videoDetails.uploadDate,
		"duration": videoDetails.duration,
		"contentUrl": `https://www.youtube.com/watch?v=${videoDetails.videoId}`,
		"embedUrl": `https://www.youtube.com/embed/${videoDetails.videoId}`,
		"interactionStatistic": {
			"@type": "InteractionCounter",
			"interactionType": { "@type": "WatchAction" },
			"userInteractionCount": videoDetails.interactionCount
		  },
	
	  } : {};
	
	  const articleSchema = article?{
		"@context": "https://schema.org",
		"@type": "BlogPosting",
		"headline": article.title,
		"image": article.image,
		"datePublished": toIso8601(article.createdAt),
		"dateModified": toIso8601(article.modifiedAt),
		"author": [{
			"@type": "Person",
			"name": article.author,
			"url": pathname
		  }]
	  }:{};
	  

	  return (
		<Helmet>
		<title>{seo.title}</title>
		<meta name="description" content={seo.description} />
		<link rel="canonical" href={seo.url} />

		{
			Object.keys(breadCrumbSchema).length > 0 && 
			<script type="application/ld+json">
				{JSON.stringify(breadCrumbSchema)}
			</script>
		
		}
		
		{
			Object.keys(creativeWorkSeriesSchema).length > 0 && 
			<script type="application/ld+json">
				{JSON.stringify(creativeWorkSeriesSchema)}
			</script>
		
		}

		{
			Object.keys(product).length > 0 && 
			<script type="application/ld+json">
				{JSON.stringify(product)}
			</script>
		
		}

		{
			Object.keys(organizationSchema).length > 0 && 
			<script type="application/ld+json">
				{JSON.stringify(organizationSchema)}
			</script>
		
		}

		{
			Object.keys(faqSchema).length > 0 && 
			<script type="application/ld+json">
				{JSON.stringify(faqSchema)}
			</script>
		
		}

		{
			Object.keys(videoSchema).length > 0 && 
			<script type="application/ld+json">
				{JSON.stringify(videoSchema)}
			</script>
		
		}

		{
			Object.keys(articleSchema).length > 0 && 
			<script type="application/ld+json">
				{JSON.stringify(articleSchema)}
			</script>
		
		}


		<script type="application/ld+json">
			{JSON.stringify(itemListSchema)}
		</script>
		
		  {children}
		</Helmet>
	  )
};

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
    pathname: PropTypes.string, // Add this line
	questions: PropTypes.arrayOf(PropTypes.shape({
		question: PropTypes.string.isRequired,
		answer: PropTypes.string.isRequired
	  })),
	
};

export default SEO;
