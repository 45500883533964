import React,{useState, useEffect} from 'react';
import Layout from '../components/layout';

import SEO from "../components/seo.jsx"
import { Helmet } from 'react-helmet';
import { useTheme } from '../context/ThemeContext';



export const Head = () => (
  <SEO 
      title="STAIRAZ: Terms of Service"
      description="     
      STAIRAZ Terms of Service
      "
      pathname="/terms/"

      >
        <html lang="en" />
 <meta name="keywords" content="AI-Powered Service, Job Application, Cover Letter Generators, Free Tools, STAIRAZ"/>
  </SEO>
);





const AboutUs = ({data}) => {
  const { isDarkMode } = useTheme();
  useEffect(() => {
    const link = document.getElementById('mode-stylesheet');
    if (link) {
      const additionalLink = document.createElement('link');
      additionalLink.rel = 'stylesheet';
      additionalLink.href = isDarkMode ? '/styles/page.css' : '/styles/pageLight.css';
      document.head.appendChild(additionalLink);

      return () => {
        document.head.removeChild(additionalLink);
      };
    }
  }, [isDarkMode]);
  
  return (
    <Layout>
       <Helmet>
        <link
          rel="stylesheet"
          href={isDarkMode ? '/styles/page.css' : '/styles/pageLight.css'}
        />
      </Helmet>
      <section className="section">
      <h1 className="section-heading">Terms of Service</h1>

    <h2>1. Introduction</h2>
    <p className="section-paragraph">
      Welcome to STAIRAZ, an online service provided by STAIRAZ, Inc. ("we," "us," or "our"). By accessing or using our services, you agree to comply with and be bound by these Terms of Service ("Terms"). Please read these Terms carefully before using our services.
    </p>

    <h2>2. Services Provided</h2>
    <p className="section-paragraph">
      STAIRAZ offers a range of services designed to help users achieve success in their academic and early career pursuits. Our services include, but are not limited to:
    </p>
    <ul>
      <li>Career consulting</li>
      <li>Academic assistance</li>
      <li>Resume building</li>
      <li>Cover letter writing</li>
    </ul>

    <h2>3. Eligibility</h2>
      <p className="section-paragraph">
      By using our services, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms.
    </p>

    <h2>4. Payment and Subscription</h2>
      <p className="section-paragraph">
      We offer two payment options for our services:
    </p>
    <ul>
      <li>Flexible (Pay-as-you-go)</li>
      <li>Subscription plans</li>
    </ul>
      <p className="section-paragraph">
      You agree to pay all fees and charges associated with your selected payment option. All payments are non-refundable except as outlined in our Refund and Cancellation Policy.
    </p>

    <h2>5. User Accounts</h2>
      <p className="section-paragraph">
      When you create an account with us, you agree to provide accurate and complete information. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account.
    </p>

    <h2>6. Use of Services</h2>
      <p className="section-paragraph">
      Our services are intended for your personal use only. You agree not to share your account or use our services for commercial purposes. Unauthorized commercial use of our services is strictly prohibited.
    </p>

    <h2>7. Refund and Cancellation Policy</h2>
      <p className="section-paragraph">
      We strive to provide high-quality services to our users. However, we have the following refund and cancellation policy:
    </p>
    <ul>
      <li>Subscription cancellations will apply to the next billing period. Services already provided will not be refunded.</li>
      <li>Refunds for provided services will only be issued in special circumstances, as determined by us at our sole discretion.</li>
    </ul>

    <h2>8. Intellectual Property</h2>
      <p className="section-paragraph">
      All content and materials provided through our services, including text, graphics, logos, and software, are the property of STAIRAZ or our licensors and are protected by intellectual property laws. You may not use, reproduce, or distribute any content from our services without our prior written permission.
    </p>

    <h2>9. Termination</h2>
      <p className="section-paragraph">
      We reserve the right to terminate or suspend your account and access to our services, without prior notice or liability, for any reason, including if you breach these Terms.
    </p>

    <h2>10. Limitation of Liability</h2>
      <p className="section-paragraph">
      To the maximum extent permitted by law, STAIRAZ shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:
    </p>
    <ul>
      <li>Your use or inability to use our services</li>
      <li>Any unauthorized access to or use of our servers and/or any personal information stored therein</li>
      <li>Any interruption or cessation of transmission to or from our services</li>
    </ul>

    <h2>11. Governing Law</h2>
      <p className="section-paragraph">
      These Terms shall be governed and construed in accordance with the laws of the jurisdiction in which STAIRAZ, Inc. is incorporated, without regard to its conflict of law provisions.
    </p>

    <h2>12. Changes to Terms</h2>
      <p className="section-paragraph">
      We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice before any new terms take effect. What constitutes a material change will be determined at our sole discretion.
    </p>

    <h2>13. Contact Us</h2>
    <p className="section-paragraph">
      If you have any questions about these Terms, please contact us at contact@stairaz.com.
    </p>

      <p className="section-paragraph">
      By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
    </p>
</section>

    <div className='divide-line'></div>

    </Layout>
  );
};

export default AboutUs;
